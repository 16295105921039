

.input-name{
    width: 100%;
    color: black;
    text-align: center;
}

.button-submit{

    width: 100%;
    height: 41px;
    font-size: 14px;
    margin-top: 14px;
    margin-bottom: 14px;
    border-radius: 8px;
    color: white;
    background-color: rgba(251, 191, 36, var(--tw-text-opacity));
}

.button-submit-disable{

    width: 100%;
    height: 41px;
    font-size: 14px;
    margin-top: 14px;
    margin-bottom: 14px;
    border-radius: 8px;
    color: black;
    background-color: #ddd;
}