


.bg-control{
    background-color: #F7AF31;
}

.img-city {
    position: absolute;
    background: url(../../assets/images/city.png);
    background-repeat: repeat-x;
    background-size: contain;
    height: 300px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    position: fixed;
    margin-top: 60px;
    width: 100%;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
}

.menu-control{
    z-index: 2;
}

@media screen and (max-width: 768px) {
  
    .img-city{
        background-position: bottom;
    }
}