

.button-control{
    background-color: #F7AF31 !important;
}

.border-game-color{
    border-color: #F7AF31 !important;
    box-shadow: 4px 4px 5px #64454B;
    margin-bottom: 8px;
}

.nm-inset-gray-100{
    border-color: #F7AF31 !important;
    box-shadow: #F7AF31 !important;
}

.count-down-color{
    color: #F7AF31 !important;
}

.width-grid{
    width: 100% !important;
}
.margin-top-custom{
    margin-top: 30px;
}

.controls-mobile{

}

.grid-custom{
    width: 95%;
    padding-left: 0px !important;
    grid-template-columns: 15% 70% 15%;
}

.rotate-button-control{

}




  @media screen and (max-width: 800px) {
    .width-grid{
        width: 80% !important;
    }

    .margin-top-custom{
        margin-top: 30px !important;
    }
  }

  @media screen and (max-width: 500px) {
    .width-grid{
        width: 100% !important;
    }
  }

  @media screen and (max-width: 480px) {
    .width-grid{
        width: 88% !important;
    }

    .controls-mobile{
        margin-top: auto !important;
        top: 0;
        bottom: 30px;
        height: 20%;
    }

    .rotate-button-control{
        margin-top: 70px !important;
    }
  }